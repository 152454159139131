import React from "react";
import "./QuestionLeftLayer.css";

const QuestionerLeftLayer = () => {
  return (
    <div
      style={{
        width: "40%",
        paddingLeft: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <div className="red-circle"></div>
      <div className="byte-logo">
        <img src="/images/Logo.svg" alt="bytecoder-logo" />
      </div>

      <div className="let-us-know" style={{ width: "70%" }}>
        <h3 style={{ margin: 0, fontSize: "2rem", fontWeight: "100" }}>
          Let us know about you!
        </h3>
        <p>
          We value your input for creating a personalized learning journey that
          aligns with your unique needs and interests, ensuring an exceptional
          learning experience.
        </p>
      </div>

      <div className="robot-ask">
        <img src="/images/robot-ask.svg" alt="robo-image" />
      </div>
    </div>
  );
};

export default QuestionerLeftLayer;
