export const RecordingApiData = [
  {
    id: 1,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Variables",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 2,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "List",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 3,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Functions",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 4,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "OODs",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 5,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Arrays",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 6,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Hashing",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 7,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Strings",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 8,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Recursion",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 9,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Graphs",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
  {
    id: 10,
    recordingImage: "/images/record-thumb.svg",
    status: "attended",
    recordingName: "Stacks",
    recordingDate: "20-September-2023",
    recordingTime: "9:30-11:30",
    recordingDescripiton:
      "Welcome to our Python class! In today's session, we will delve into the fascinating world of Object-Oriented Programming (OOP). Get ready to explore the principles and concepts that allow us to organize code in a morestructured and efficient manner. Read More",
  },
];
