import React, { useEffect, useState } from "react";
import { DASH_PROGRESS_CARD } from "../service/DashboardsProgressCard";
import "./ProfileCourses.css";
import { useNavigate } from "react-router-dom";
import * as courseApi from "../service/CoursesApi";

const ProfileCourses = () => {
  const navigate = useNavigate();

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    courseApi.getAllEnrolledCourses("", "", 4).then((result) => {
      console.log(result);
      return setCourses(result);
    });
  }, []);

  return (
    <div className="profile-courses-container">
      <div className="profile-courses-details">
        <div className="user-ongoing-courses">
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.1rem",
              marginBottom: "1rem",
            }}
          >
            Ongoing
          </p>

          <div className="ongoing-courses-container">
            {courses.map((course) => {
              return (
                <div className="ongoing-courses-card">
                  <div className="courses-certi-and-name">
                    <div className="certi-img">
                      <img src="/images/certi.svg" alt="" />
                    </div>

                    <div className="courses-name-and-progress">
                      <p style={{fontSize:'1.2rem'}}>{course.subjectName}</p>
                      <p style={{ color: "#349367" }}>
                        {course.progress}% Completed
                      </p>
                    </div>
                  </div>

                  <div className="go-and-download-courses">
                    <div
                      className="go-to"
                      onClick={() => navigate("/programs")}
                    >
                      <p>view course</p>
                    </div>
                    <div className="end-courses">
                      <p>End the Course</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="completed-courses">
          <div className="completed-and-end-course">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              Completed
            </p>

            {/* <div className="end-courses">
              <p>End the Course</p>
            </div> */}
          </div>

          <div className="ongoing-courses-container">
            {DASH_PROGRESS_CARD.slice(0, 1).map((courses) => {
              return (
                <div className="ongoing-courses-card">
                  <div className="courses-certi-and-name">
                    <div className="certi-img">
                      <img src="/images/certi.svg" alt="" />
                    </div>

                    <div className="courses-name-and-progress">
                      <p>{courses.courseName}</p>
                      <p>10 Kb</p>
                    </div>
                  </div>

                  <div className="go-and-download-courses">
                    {/* <div
                      className="go-to"
                      onClick={() => navigate("/programs")}
                    >
                      <p>Go-To course</p>
                    </div> */}
                    <div className="download">
                      <p>Download</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCourses;
