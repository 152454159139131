export const CHAPTER_WISE_DATA = [
  {
    chapter: "Chapter 1",
    degress: 59,
  },
  {
    chapter: "Chapter 2",
    degress: 61,
  },
  {
    chapter: "Chapter 3",
    degress: 55,
  },
  {
    chapter: "Chapter 4",
    degress: 78,
  },
  {
    chapter: "Chapter 5",
    degress: 71,
  },
  {
    chapter: "Chapter 6",
    degress: 56,
  },
  {
    chapter: "Chapter 7",
    degress: 67,
  },
];

export const ANALYSIS = [
  "Chapter - 1 Performance is very poor",
  "Overall performance is average.",
  "Complete the challenges withing time",
];
