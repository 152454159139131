import React from "react";
import "./AssignmentTest.css";
import { ASSIGNMENTS_QUESTIONS } from "../../../../service/AssignmentQuestions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmPopup from "../../../../Atoms/ConfirmPopup";


const AssignmentTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    new Array(ASSIGNMENTS_QUESTIONS.length).fill(null)
  );
  const [markedForReview, setMarkedForReview] = useState(
    new Array(ASSIGNMENTS_QUESTIONS.length).fill(false)
  );
  const [answeredQuestions, setAnsweredQuestions] = useState(
    new Array(ASSIGNMENTS_QUESTIONS.length).fill(false)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);


  const handleQuestionClick = (questionNumber) => {
    setCurrentQuestion(questionNumber - 1);
  };

  const handleArrowClick = (direction) => {
    if (direction === "left" && currentQuestion > 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    } else if (
      direction === "right" &&
      currentQuestion < ASSIGNMENTS_QUESTIONS.length - 1
    ) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    }
  };

  const handleOptionClick = (optionIndex, ans) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[currentQuestion] = { optionIndex, ans };
    setSelectedOptions(updatedOptions);

    // Clear review status when an option is selected
    setMarkedForReview((prev) => {
      const updatedReviewStatus = [...prev];
      updatedReviewStatus[currentQuestion] = false;
      return updatedReviewStatus;
    });
    setAnsweredQuestions((prev) => {
      const updatedAnswerStatus = [...prev];
      updatedAnswerStatus[currentQuestion] = true;
      return updatedAnswerStatus;
    });
  };

  const clearResponse = () => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[currentQuestion] = null;
    setSelectedOptions(updatedOptions);
  };

  const markForReview = () => {
    setMarkedForReview((prev) => {
      const updatedReviewStatus = [...prev];
      updatedReviewStatus[currentQuestion] = !prev[currentQuestion];
      return updatedReviewStatus;
    });

    // Move to the next question when marking for review
    handleArrowClick("right");
  };

  const navigate = useNavigate();

  const handleOpenConfirmPopup = () => {
    setIsConfirmPopupOpen(true);
  };

  const handleCloseConfirmPopup = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleConfirmSubmission = () => {
    setIsConfirmPopupOpen(false);
    // Call the logic to submit the assignment
    AssignmentTestSubmit();
  };

  const AssignmentTestSubmit = () => {

    setIsSubmitting(true);

    const selectedOptionsWithDetails = selectedOptions.map((selectedOption, index) => {
      const question = ASSIGNMENTS_QUESTIONS[index];
      return {
        questionNumber: index + 1,
        selectedOption: selectedOption,
        questionDetails: question,
      };
    });

    console.log("Selected Options with Details:", selectedOptionsWithDetails);

    toast.success("test submit successfully!!");
    setTimeout(() => {
      navigate("/thankyou");
    }, 4000);
    setIsSubmitting(false);
  };

  return (
    <div className="assignment-test-container">
      <p>
        <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          Assignments
        </span>{" "}
        &gt; <span className="assignment-chapter">Chapter 1</span>
      </p>
      <div className="assignment-questions-numbers-box">
        <div className="left-side-arrow">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => handleArrowClick("left")}
          ></i>
        </div>
        {ASSIGNMENTS_QUESTIONS.map((question, index) => {
          return (
            <div
              className={`question-numbers ${
                currentQuestion + 1 === question.questionNumber ? "active" : ""
              } ${markedForReview[index] ? "marked-for-review" : ""} ${
                answeredQuestions[index] ? "answered" : ""
              }`}
            >
              <ul>
                <li>{question.questionNumber}</li>
              </ul>
            </div>
          );
        })}
        <div className="right-side-arrow">
          <i
            className="fa-solid fa-arrow-right"
            onClick={() => handleArrowClick("right")}
          ></i>
        </div>
      </div>

      <div className="assignment-question-contianer">
        <div className="questions-change">
          <div>
            <p style={{ marginLeft: "1rem" }}>Question-{currentQuestion + 1}</p>
          </div>

          <div className="response-and-question">
            <div className="clear-response" onClick={clearResponse}>
              <p>Clear Response</p>
            </div>

            <div className="switch-question">
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => handleArrowClick("left")}
              ></i>
              <p>Question</p>
              <i
                className="fa-solid fa-arrow-right"
                onClick={() => handleArrowClick("right")}
              ></i>
            </div>
          </div>
        </div>

        <div className="question-details-and-options">
          <div className="question-detail">
            <p>{ASSIGNMENTS_QUESTIONS[currentQuestion].questionDescripiton}</p>
          </div>

          <div className="answer-options">
            <p style={{ fontWeight: "bold" }}>Options:</p>

            <div className="option-selection">
              {ASSIGNMENTS_QUESTIONS[currentQuestion].questionOptions.map(
                (option, index) => (
                  <div
                    key={index}
                    className={`ans ${
                      selectedOptions[currentQuestion]?.optionIndex === index
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleOptionClick(index, option)}
                  >
                    <h3
                      className={`ans ${
                        selectedOptions[currentQuestion]?.optionIndex === index
                          ? "selected-ans"
                          : ""
                      }`}
                    >
                      {String.fromCharCode(65 + index)}
                    </h3>
                    <p>{option}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className="assignment-actions">
          <div className="assignment-action-btns">
            {currentQuestion <= ASSIGNMENTS_QUESTIONS.length - 2 && (
              <div
                className="save-and-next"
                onClick={() => handleArrowClick("right")}
              >
                <p>Save & Next</p>
              </div>
            )}
            <div className="review-mark" onClick={markForReview}>
              <p>Mark for Review</p>
            </div>

            <div className="vertical-line" style={{ height: "100%" }}></div>
            <div className="assignment-submit" onClick={handleOpenConfirmPopup}>

              <img
                style={{ maxWidth: "1em" }}
                src="/images/send-white.svg"
                alt=""
              />
              <p>Submit</p>

              <ToastContainer position="top-center" autoClose={4000} />
            </div>
          </div>

          <ConfirmPopup
            isOpen={isConfirmPopupOpen}
            message="Are you sure you want to submit the assignment?"
            onConfirm={handleConfirmSubmission}
            onCancel={handleCloseConfirmPopup}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignmentTest;
