import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const ChapterWiseBar = () => {
  const labels = [
    "Chapter 1",
    "Chapter 2",
    "Chapter 3",
    "Chapter 4",
    "Chapter 5",
    "Chapter 6",
    "Chapter 7",
    "Chapter 8",
    "Chapter 9",
    "Chapter 10",
    "Chapter 11",
    "Chapter 12",
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Chapters",
        backgroundColor: "#3FA959",
        data: [28, 30, 50, 75, 75, 52, 55, 50, 35, 60, 20, 10],
        fill: false,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        color:""
      },
      legend: {
        position: "bottom",
        labels: {
          boxHeight: 10,
          boxWidth: 5,
        },
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar
        height={"100%"}
        data={data}
        plugins={[ChartDataLabels]}
        options={options}
      />
    </div>
  );
};

export default ChapterWiseBar;
