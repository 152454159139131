import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as CoursesApi from "../../service/CoursesApi";
import CoursePrograms from "../../Organism/Programs";
import "./Subjects.css";
import Programs from "../../Organism/ProgramsPage";



export default function NewProgramEnrollemnt(props) {
  const navigate = useNavigate();
  const [recommendedcourses, setRecommendedCourses] = useState([]);


  useEffect(() => {
    CoursesApi.getRecommendedCourses().then((courses)=>   setRecommendedCourses(courses) );
  }, []);


    return (
      <div className="subjects-container">
        <div className="subjects-and-add-courses">
          <div className="subjects-heading">
            <h4>Upcoming mentorship Courses</h4>
          </div>
         
        </div>
        <div className="discover-courses-container">
          <CoursePrograms courses={recommendedcourses.slice(0, 3)} />
        </div>
        <Programs />
      </div>
    );
  
}

