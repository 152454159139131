export const ASSIGNMENTS_QUESTIONS = [
  {
    questionID: 1,
    questionNumber: 1,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort? Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 2,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 3,
    questionDescripiton:
      "A program P reads in 500 integers in the range [0..100] representing the scores of 500 students. It then prints the frequency of each score above 50. What would be the best way for P to store the frequencies? ",
    questionOptions: [
      "An array of 50 numbers",
      "An array of 100 numbers",
      "An array of 200 numbers",
      "A dynamically allocated array of 550 numbers",
    ],
  },
  {
    questionID: 1,
    questionNumber: 4,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 5,
    questionDescripiton:
      "Suppose the numbers 7, 5, 1, 8, 3, 6, 0, 9, 4, 2 are inserted in that order into an initially empty binary search tree. The binary search tree uses the usual ordering on natural numbers. What is the in-order traversal sequence of the resultant tree?",
    questionOptions: [
      "7 5 1 0 3 2 4 6 8 9",
      "0 2 4 3 1 6 5 9 8 7",
      "0 1 2 3 4 5 6 7 8 9",
      "	9 8 6 4 2 3 0 1 5 7",
    ],
  },
  {
    questionID: 1,
    questionNumber: 6,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 7,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 8,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 9,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
  {
    questionID: 1,
    questionNumber: 10,
    questionDescripiton:
      "Which of the following algorithm implementations is similar to that of an insertion sort?",
    questionOptions: ["Binary Heap", "Quick Sort", "Merge Sort", "Radix Sort"],
  },
];
