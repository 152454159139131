import React from "react";
import "./Chapters.css";
import { getSideNav } from "../../../../Constants/Constants";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as enrolledApi from "../../../../service/EnrolledApi";

const Chapters = (props) => {
  const [Open, setOpen] = useState(1);
  const navigate = useNavigate();
  const params = useParams();
  const [courseChapters, setcourseChapters] = useState({ chapters: ["sd"] });

  useEffect(() => {
    enrolledApi.getCourseChapters(params.courseCode).then((content) => {
      console.log(content)
      setcourseChapters(content)
    });
    
  }, []);

  const handleOpenChapters = (index) => {
    setOpen(index);
  };

  return (
    <div className="chapters-container">
      <div className="chapters-name">
        <h2>
          <span>{courseChapters.title}</span>{" "}
        </h2>
        <h3>Chapters</h3>
      </div>

      {courseChapters.chapters.map((chapter, index) => (
        <div
          className="chapters-detailed-container"
          onClick={() => handleOpenChapters(index)}
        >
          
          

          <div className="chapter-name-and-open">
            <div className="chapter-icon-and-details">
              <div className="chapter-icon">
                <img src="/images/book.svg" alt="" />
              </div>

              <div className="chapter-name-and-title">
                <h3>Chapter {++index}</h3>
                <p>{chapter.chapterTitle}</p>
              </div>
            </div>

            <div className="open-chapter">
              <i
                className={` ${
                  Open == index
                    ? "fa-solid fa-angle-down"
                    : "fa-solid fa-angle-down fa-rotate-270"
                }`}
              ></i>
            </div>
          </div>
          {Open === index && (
            <div className="chapters-type">
              {/* <div className="bulb-img">
            <img src="/images/bulb.svg" alt="" />
          </div>
          <div className="globe-img">
            <img src="/images/globe2.svg" alt="" />
          </div> */}
              <div className="chapter-type-and-icon">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(
                      "/programs/" + params.courseCode + "/learn/sessions"
                    )
                  }
                >
                  <div className="chapter-type-img">
                    <img src="/images/clipboard.svg" alt="" />
                  </div>
                  <p>Sessions</p>
                </div>
              </div>
              <div className="chapter-type-and-icon">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(
                      "/programs/" +
                        params.courseCode +
                        "/learn/quick-notes/" +
                        "Id"
                    )
                  }
                >
                  <div className="chapter-type-img">
                    <img src="/images/clipboard.svg" alt="" />
                  </div>
                  <p>Quick Notes</p>
                </div>
              </div>
              <div className="chapter-type-and-icon">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(
                      "/programs/" + params.courseCode + "/learn/practices/"
                    )
                  }
                >
                  <div className="chapter-type-img">
                    <img src="/images/code-editor.svg" alt="" />
                  </div>
                  <p>Daily Practice</p>
                </div>
              </div>
              <div className="chapter-type-and-icon">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(
                      "/programs/" + params.courseCode + "/learn/assignments/"
                    )
                  }
                >
                  <div className="chapter-type-img">
                    <img src="/images/assignment.svg" alt="" />
                  </div>
                  <p>Assignments</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Chapters;
