import * as Congs from "./Configurations";

export const ASSIGNMENTS_CHAPTERS = [
  {
    assignmentID: 1,
    assignmentChapter: "Chapter 1",
    assignmentName: "Introduction to Object Oriented Programming",
    assignmentTest: [
      {
        testID: 1,
        testName: "Classes, Objects [Code]",
      },
      {
        testID: 2,
        testName: "Classes, Objects [Code]",
      },
    ],
  },
  {
    assignmentID: 2,
    assignmentChapter: "Chapter 2",
    assignmentName: "Introduction to Object Oriented Programming",
    assignmentTest: [
      {
        testID: 2,
        testName: "Classes, Objects [Code]",
      },
      {
        testID: 1,
        testName: "Classes, Objects [Code]",
      },
    ],
  },
  {
    assignmentID: 3,
    assignmentChapter: "Chapter 3",
    assignmentName: "Introduction to Object Oriented Programming",
    assignmentTest: [
      {
        testID: 1,
        testName: "Classes, Objects [Code]",
      },
      {
        testID: 2,
        testName: "Classes, Objects [Code]",
      },
    ],
  },
  {
    assignmentID: 4,
    assignmentChapter: "Chapter 4",
    assignmentName: "Introduction to Object Oriented Programming",
    assignmentTest: [
      {
        testID: 1,
        testName: "Classes, Objects [Code]",
      },
      {
        testID: 2,
        testName: "Classes, Objects [Code]",
      },
    ],
  },
];

export function getChaptersAssignment(courseCode) {
  console.log("fetching getChaptersAssignment " + courseCode);

  // const endpoint = Congs.createAssignmentEndpoint(courseCode);
  return fetch(Congs.GET_ENROLLED_COURSE_ASSIGNMENTS_API + courseCode + "/assignments")
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}
