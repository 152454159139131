import React, { useState } from "react";
import "./JobAssesments.css";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmPopup from "../../Atoms/ConfirmPopup";

const JobAssesments = () => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const navigate = useNavigate();

  const params = useParams()

  const handleGoBack = () => {
    navigate(-1);
  };


  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const handleTakeAssessment = () => {
    if (checkboxChecked) setIsConfirmPopupOpen(true);

    // else {
    //   alert("Please agree to the terms before taking the assessment.");
    // }
  };

  const handleConfirmSubmission = () => {
    setIsConfirmPopupOpen(false);
    // navigate("/undermaintainance");
    navigate("/jobs/"+params.code+"/assesment/1");

  };

  const handleCancelSubmission = () => {
    setIsConfirmPopupOpen(false);
  };


  return (
    <div className="jobs-asses-container">
      <div className="job-assesment">
        <div className="go-back" onClick={handleGoBack}>
          <i class="fa-solid fa-angle-left"></i>

          <h4>Go Back</h4>
        </div>

        <div className="assesment-details">
          <div className="job-img-and-title">
            <div className="job-logo">
              <img src="/images/job-logo.svg" alt="" />
            </div>

            <div className="title-and-designation">
              <h4>Amazon India</h4>
              <p>UI/UX Designer</p>
            </div>
          </div>

          <div className="job-description">
            <p>
              Before proceeding with your application for this position, please
              complete the eligibility assessment to determine if you meet the
              necessary criteria for this role.
            </p>
          </div>
          <hr className="hr-line" />

          <div className="instructions">
            <h4>Important Instructions:</h4>

            <ol>
              <li>
                Read the Instructions Thoroughly: Begin by carefully reading all
                provided instructions and guidelines. Understand what is
                expected of you during the assessment.
              </li>
              <li>
                Check Technical Requirements: Ensure your computer, internet
                connection, and any required software or browser extensions are
                up to date and functioning properly.
              </li>
            </ol>

            <div className="check-terms">
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="termsCheckbox">

                By clicking 'Take Assessment,' you agree to BYTECODE's Terms and
                Policies.
              </label>
            </div>

            <div
              className={`take-asses-btn ${
                !checkboxChecked ? "disabled" : "not-disabled"
              }`}
              onClick={handleTakeAssessment}
            >
              <p>Take Assessment</p>
            </div>
          </div>

          {isConfirmPopupOpen && (
            <ConfirmPopup
              isOpen={isConfirmPopupOpen}
              message="Are you sure you want to take the assessment?"
              onConfirm={handleConfirmSubmission}
              onCancel={handleCancelSubmission}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default JobAssesments;
