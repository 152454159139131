import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as CoursesApi from "../../service/CoursesApi";
import CoursePrograms from "../../Organism/Programs";
import "./Subjects.css";
import NewProgramEnrollemnt from "./NewProgramEnrollment";
import ProgressBar from "../../Atoms/ProgressBar";

function Subjects() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [recommendedcourses, setRecommendedCourses] = useState([]);
  const [screenWidth, setScreenWidth] = useState(1920);


  useEffect(() => {
      setScreenWidth(window.innerWidth);
    }, []);



  useEffect(() => {
    CoursesApi.getAllEnrolledCourses("", "", 4).then((cs) =>
      setCourses(cs)
    );
    CoursesApi.getRecommendedCourses().then((cs) =>
      setRecommendedCourses(cs)
    );
  }, []);

  if (courses.length == 0) {
    return <NewProgramEnrollemnt />;
  } else {
    return (
      <div className="subjects-container">
        <div className="subjects-and-add-courses">
          <div className="subjects-heading">
            <h2>Enrolled Courses</h2>
          </div>
          <div
            className="add-courses"
            onClick={() => navigate("/enroll-program")}
          >
            <i className="fa-solid fa-plus"></i>
            <p>Add Courses</p>
          </div>
        </div>

        <div className="dash-subject-progress-container">
          {courses.slice(0, 4).map((course, idx) => (
            <div
              key={idx}
              style={{ margin: "1em", height: "12rem" }}
              className="subject-item"
            >
              <div className="course-name">
                {/* <h6>Course</h6> */}
                <h2>{course.title}</h2>
              </div>
              <div className="progress-bar-and-course-container">
                {/* <div className="progress-circle-one"></div> */}
                {/* <div className="progress-circle-two"></div> */}
                <div className="course-title-and-actions">
                  <div style={{ width: "40%" }}>
                    <h5 style={{ margin: 0 }}>chapter-{course.currentChapter}</h5>
                    <p style={{fontSize:'22px'}}>{course.currentChapterTitle}</p>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/programs/" + course.code + "/learn/chapters ")
                    }
                  >
                    Continue 
                  </button>
                  <button onClick={() => navigate("/programs/" + course.code)}>
                    View Course
                  </button>
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                  }}
                >
                  <ProgressBar filled={course.progress} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="discover-courses">
          <div className="discover-heading">
            <h2>Discover tailored course recommendations just for you!</h2>
          </div>
          <div className="discover-courses-container">
            {screenWidth < 1920 ? (
              <CoursePrograms courses={recommendedcourses.slice(0, 2)} />
            ) : (
              <CoursePrograms courses={recommendedcourses.slice(0, 3)} />
            )}{" "}
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                cursor: "pointer",
              }}
              onClick={() => navigate("/enroll-program")}
            >
              <div className="right-arrow" onClick={()=>navigate("")}>
                <i className="fa-solid fa-arrow-right"></i>
              </div> 

              <p style={{ transform: "translateX(-10rem)" }}>View More</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Subjects;
