import React, { useEffect, useState } from "react";
import { DASHBOARD_TABS } from "../Constants/Constants";
import { Collapse, Paper } from "@mui/material";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./CSS/DashboardSideNavBar.css";
import { getSideNav } from "../Constants/Constants";
import * as loginApi from "../service/LoginRegistrationApi";
import { useRef } from "react";


export function DashboardHeader() {
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    loginApi.getUserInfo().then((response) => {
      console.log(response)
      if(response.status == 200){
          return  response.json().then(data => setUserInfo(data))
      }else{
        window.open("https://bytecoder.in/login", '_self')
        return  response.json()
      }
    })
    .catch((e) => 
    {
      console.log("Error " + e)
      window.open("https://bytecoder.in/login", '_self')

    });

  }, []);

  const location = useLocation();
  const pathLocation = [
    "/login",
    "/register",
    "/undermaintainance",
    "/mobile-maintainance",
    "/thankyou",
    "/questioner",
  ];

  const loginAndRegister = pathLocation.includes(location.pathname);

  const handleProfile = () => {
    console.log("clicked");
    setShowProfile(!showProfile);
  };

  const handleUserLink = (path) => {
    navigate(path);
  };

  const handleToggleProfile = () => {
    navigate("/profile");
  };

  const logoutUser = ()=>{
    loginApi.logoutUser().then((response) => {
      console.log(response)
      if(response.status == 200){
          response.json().then((json) => {
            window.open(json.logout_url, '_self')
          })
      } else{
        console.log("logout is not working")
      }
    })

  }

  const profileRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Click outside the profile card
        setShowProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!loginAndRegister || !userInfo)
    return (
      <div className="dash-header-container">
        <div className="dash-header">
          {/* <div className="header-circle"></div> */}

          <div className="user-name">
            {/* <div className="ring"></div> */}
            <h3 style={{ position: "relative", textTransform:"uppercase", color:"white" }}>HEY {userInfo.name}</h3>
          </div>

          <div className="user-img-and-name" onClick={handleProfile}>
            <div className="user-profile-image">
              <img src={userInfo.imageUrl== null ? "./images/profile_image.png" : userInfo.imageUrl} alt="" />
            </div>

            <span style={{ textTransform: "capitalize" }}>
              {userInfo?.name ? userInfo.name.split(" ")[0] : ""}
            </span>

            {/* <h3>Karan</h3> */}
            <Collapse in={showProfile}>
              <Paper
                ref={profileRef}
                elevation={3}
                className="user-profile-box"
                style={{ borderRadius: "10px", width: "10rem" }}
              >
                <div className="profile-navs">
                  <p>{userInfo.email}</p>

                  <hr style={{ width: "90%" }} />
                  <div
                    className="user-navs"
                    onClick={() => handleUserLink("/")}
                  >
                    <i className="fa-solid fa-sliders fa-rotate-90"></i>
                    <h4>Home</h4>
                  </div>
                  <div className="user-navs" onClick={handleToggleProfile}>
                    <i className="fa-solid fa-file"></i>
                    <h4>Profile</h4>
                  </div>
                  <div className="user-navs"
                    onClick={() => logoutUser()}>
                    <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                    <h4>Sign out</h4>
                  </div>
                </div>
              </Paper>
            </Collapse>
          </div>
        </div>
      </div>
    );
}

export function DashboardSideNavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [nav_tabs, set_tabs] = useState(getSideNav(""));

  const dashLocations = [
    "/jobs/assesments",
    "/dashboard/profile",
    "/dashboard/all-recordings",
    "/class-player",
    "/login",
    "/register",
    "/undermaintainance",
    "/mobile-maintainance",
    "/profile",
    "/user-courses",
    "/thankyou",
    "/questioner"
  ].includes(location.pathname);
  const [activeLink, setActiveLink] = useState("/dashboard");

  useEffect(() => {
    setActiveLink(location.pathname);
    location.pathname.includes("learn")
      ? set_tabs(getSideNav("programs"))
      : set_tabs(getSideNav(""));
  }, [location.pathname]);

  // console.log(location, "hewre is the locations");

  if (!dashLocations)
    return (
      <div className="dash-nav-container">
        <div className="byte-logo" onClick={() => navigate("/")}>
          <img src="/images/Logo.svg" alt="" />
        </div>

        <div className="all-dash-nav-link">
          {nav_tabs.map((nav) => (
            <div className="dash-navs" key={nav.tag}>
              <Link
                className={
                  activeLink.toLowerCase().includes(nav.tag.toLowerCase())
                    ? "active-link"
                    : "inactive-link"
                }
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".5em",
                  textDecoration: "none",
                }}
                to={nav.path}
              >
                {nav.icon}
                {nav.tag}
              </Link>
            </div>
          ))}
        </div>

        {location.pathname.includes("/programs") ? (
          <div className="heart-book-img">
            <img src="/images/heart-book.svg" alt="" />
          </div>
        ) : (
          <div className="dash-ad">
            <div className="ad-dash-in">
              <h4>Experience tailored updates just for you.</h4>
              <div className="dash-join">Join Now</div>
            </div>

            <div className="dash-ad-img">
              <img src="/images/dash-ad-img.svg" alt="" />
            </div>
          </div>
        )}
      </div>
    );
}
