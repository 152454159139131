// CandidateForm.js

import React, { useState } from "react";
import "./CandidateProfilePage.css";
import UserProfile from "../../Organism/UserProfile";
import { useNavigate } from "react-router-dom";
import UserProfileNav from "./UserProfileNav";
import ProfileCourses from "../../Organism/ProfileCourses";

function CandidateForm() {
  const [nav, setNav] = useState("profile");

  return (
    <div className="candidate-form-container">
      <UserProfileNav nav={nav} setNav={setNav} />

      {nav === "profile" ? <UserProfile /> : <ProfileCourses />}
    </div>
  );
}

export default CandidateForm;
