import * as Congs from "./Configurations";

export async function registerNewUser(userData) {
  console.log("fetching shortcuts");

  const response = await fetch(Congs.NEW_REGISTRATION, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((e) => console.log("Error " + e));

  const result = await response.json();
  console.log(result);
}


export function loginUser(userData) {
  console.log("logging user");

  return fetch(Congs.Login_USER, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      // toast.error("Please enter right credentials.");
      console.log("error");
    });
}

export function logoutUser() {
  console.log("logoutUser user");
  const userData = {uniqueUserId : Congs.uniqueUserId, token: Congs.token}
  return fetch(Congs.LOGOUT_USER, {
    method: "POST",
    body: JSON.stringify(userData),
    headers: Congs.HEADERS,
  }).catch((e) => {
      // toast.error("Please enter right credentials.");
      console.log("error");
    });
}

export function getUserInfo() {
  console.log("fetching userInfo");
    return fetch(Congs.GET_USER_INFO)
}

export function updateUserInfo(userInfo) {
  console.log("updating userInfo");
  return fetch(Congs.UPDATE_USER_INFO, {
    method: "PUT",
    headers: Congs.HEADERS,
    body: JSON.stringify(userInfo),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}

