import "./Dashboard.css";
import React, { useState } from "react";
import Subjects from "./Subjects/Subjects";
import CandidateForm from "./profile/CandidateProfilePage";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import NewProgramEnrollemnt from "./Subjects/NewProgramEnrollment";
import CoursePlayer from "./Subjects/ProgramLearning/Sessions/CoursePlayer";
import {
  DashboardSideNavBar,
  DashboardHeader,
} from "../Molecule/DashboardSideNavBar";
import CourseProgramDetailsPage from "./Subjects/CourseProgramDetailsPage";
import MyDashboard from "./MyDashboard/MyDashboard";
import NewEnrollDashboard from "./MyDashboard/NewEnrollDashboard";
import JobAssesments from "./Jobs/JobAssesments";
import BlogDetailsPage from "./Blogs/BlogReadPage";
import Jobs from "./Jobs/JobsPage";
import JobDetails from "./Jobs/JobDetailsPage";
import BlogReadingComponent from "./Blogs/BlogsPage";
import Recordings from "./Subjects/ProgramLearning/Sessions/Recordings/Recordings";
import ClassPlayer from "./Subjects/ProgramLearning/Sessions/Recordings/ClassPlayer";

import Assignments from "./Subjects/ProgramLearning/Assignments/Assignments";
import AssignmentTest from "./Subjects/ProgramLearning/Assignments/AssignmentTest";
import Practice from "./Subjects/ProgramLearning/Practice/Practice";
import DisplayChallege from "./Subjects/ProgramLearning/Practice/PracticeChallengeDisplay";
import LiveClasses from "./Subjects/ProgramLearning/Sessions/Sessions";
import Bookmark from "./Subjects/ProgramLearning/Bookmarks/Bookmark";
import Chapters from "./Subjects/ProgramLearning/Chapters/Chapters";
import QuickNotes from "./Subjects/ProgramLearning/QuickNotes/QuickNotes";

import ProfileCourses from "../Organism/ProfileCourses";

import Progress from "./Subjects/ProgramLearning/Progress/Progress";
import UnderMaintainance from "../LoginPages/MaintainancePages/UnderMaintainance";
import { ThankYou } from "../Atoms/ThankYou";
import Questioner from "./profile/Questioner";

export default function Dashboard(props) {
  return (
    <div className="dashboard-main-container">
      <Router>
        <DashboardHeader />
        <div
          style={{ marginLeft: "2px", display: "flex" }}
          className="dashboard-content"
        >
          <DashboardSideNavBar />
          <Routes>
            {/* Dashboard Components */}
            <Route path="/" element={<MyDashboard />} />
            <Route path="/dashboard" element={<MyDashboard />} />
            <Route path="/enroll-dashboard" element={<NewEnrollDashboard />} />

            {/* Subjects Components */}
            <Route path="/programs" element={<Subjects />} />
            <Route path="/enroll-program" element={<NewProgramEnrollemnt />} />
            <Route path="/programs/:courseCode" exact element={<CourseProgramDetailsPage />}/>
            <Route path="/programs/:courseCode/learn/chapters" exact element={<Chapters />} />

            {/* live classes screen */}
            <Route path="/programs/:courseCode/learn/sessions" element={<LiveClasses />} />

            {/* all recording lectures */}
            <Route path="/programs/:courseCode/learn/sessions/all-recordings" element={<Recordings />} />
            <Route path="/programs/:courseCode/learn/sessions/:sessionId" element={<ClassPlayer />} />

            {/* quick-notes  */}
            <Route path="dashboard/bookmarks-classes" element={<Bookmark />} />
            <Route path="/programs/:courseCode/learn/quick-notes" element={<QuickNotes />} />
            <Route path="/programs/:courseCode/learn/quick-notes/:id" element={<QuickNotes />} />

            {/* Assignments Components */}
            <Route path="/programs/:courseCode/learn/assignments" element={<Assignments />} />
            <Route path="/programs/:courseCode/learn/assignments/:id" element={<AssignmentTest />} />

            {/* progress Components */}
            <Route path="/programs/:courseCode/learn/progress" element={<Progress />} />

            {/* Practice Components */}
            <Route path="/programs/:courseCode/learn/practices" element={<Practice />} />
            <Route path="/programs/:courseCode/learn/practices/:code" element={<DisplayChallege />} />

            {/* not in use */}
            <Route path="/learning/play/:id" element={<CoursePlayer />} />

            {/* Blog Components */}
            <Route path="/blogs" element={<BlogReadingComponent />} />
            <Route path="/blogs/:id" element={<BlogDetailsPage />} />

            {/* Jobs COmponents */}
            <Route path="/jobs" element={<Jobs />} />
            <Route path="job-details/:code" element={<JobDetails />} />

            <Route path="/jobs/:code/assesment" element={<JobAssesments />} />
            <Route path="/jobs/:code/assesment/:id" element={<AssignmentTest />} />

            {/* user profile courses  */}
            {/* <Route path="/dashboard/blogs" element={<MediumLikeComponent />} /> */}
            <Route path="/profile" element={<CandidateForm />} />
            <Route path="/user-courses" element={<ProfileCourses />} />

            {/* Under Maintainance */}
            <Route path="/undermaintainance" element={<UnderMaintainance />} />

            {/* thanks screen */}
            <Route path="/thankyou" element={<ThankYou />} />

            {/* questioner screen */}
            <Route path="/questioner" element={<Questioner />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}
