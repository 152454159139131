import React from "react";
import "./Recordings.css";
import RecordingCard from "../../../../../Molecule/RecordingCard";
import { useNavigate } from "react-router-dom";

const Recordings = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="all-recordings-container">
      <div className="recording-container">
        <div className="recording-back" onClick={handleBack}>
          <p> &lt; Back </p>
        </div>
        <div className="all-recordings">
          <h2 className="all-recording-heading">All Recordings</h2>

          {/* recording Coontainer card */}
          <RecordingCard />
        </div>
      </div>
    </div>
  );
};

export default Recordings;
