import React from "react";
import CourseCard from "../Molecule/CourseCard";
import { useNavigate } from "react-router-dom";

export default function CoursePrograms(props) {
  const navigation = useNavigate();
  const openCourse = (code) => {
    navigation("/programs/" + code);
  };

  return (
    <div
    className="program-card-container"
    >
      {props.courses.map((course) => {
        return (
            <CourseCard
              course={course}
              openCourse={() => {
                openCourse(course.code);
              }}
            />
        );
      })}
  
    </div>


  );
}
