import React from "react";
import { useNavigate } from "react-router-dom";

const RecordedCard = (props) => {
  const { recording, code } = props;

  const navigate = useNavigate();

  const handleWatchVideo = (sessionId) => {
    navigate("/programs/" + code + "/learn/sessions/"+sessionId );
  };

  return (
    <div key={recording.id} className="recording-classes-list">
      <div className="orange-line"></div>
      <div className="class-poster">
        <img src={recording.thumbnailImage} alt="" />
      </div>

      <div className="classes-details">
        <div className="class-lang">
          <p>English</p>
        </div>

        <h2 className="recording-class-heading">{recording.title}</h2>
        <p className="recording-description">
          {recording.description}
        </p>
      </div>

      <div className="class-join-and-time">
        <div className="watch-video" onClick={()=>handleWatchVideo(recording.id)}>
          <i class="fa-regular fa-eye"></i>
          <p>watch video</p>
        </div>
        <p style={{ color: "red" }}>Ended at {recording.time}</p>
        <p> {recording.date}</p>

      </div>

    </div>
  );
};

export default RecordedCard;
