// MediumLikeComponent.js
import React, { useEffect, useState } from "react";
import * as BlogsService from "../../service/BlogsApi";
import SearchAndPopularTags from "../../Atoms/SerachAndPopularTags";
import { BlogCard } from "../../Molecule/BlogsCard";
import "./BlogsPage.css";
import { useNavigate } from "react-router-dom";

export default function BlogComponent() {
  const [blogs, setBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [populerShortcuts, setpopulerShortcuts] = useState([]);
  const [interest, setInterest] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    BlogsService.getWebsiteBlogs("", "").then((response) => {
      setBlogs(response.response);
      console.log(response.response);
    });
    BlogsService.getPopulerShortcuts().then((shortcuts) =>
      setpopulerShortcuts(shortcuts)
    );
  }, []);

  useEffect(() => {
    BlogsService.getWebsiteBlogs("", interest).then((response) =>
      setBlogs(response.response)
    );
  }, [interest]);

  const searchBlogs = (e) => {
    e.preventDefault();
    console.log(searchText);
    BlogsService.getWebsiteBlogs(searchText, interest).then((response) =>
      setBlogs(response.response)
    );
  };

  const handleClick = (id) => {
    navigate(`/blogs/${id}`);
  };

  return (
    <div className="blog-area">
      <SearchAndPopularTags
        props={{
          interest,
          populerShortcuts,
          setInterest,
          searchText,
          setSearchText,
          searchEvent: searchBlogs,
        }}
      />

      {console.log(
        "blogs --> ",
        blogs.slice(0, 1).map((blog) => blog.title)
      )}

      {blogs.slice(0, 1).map((blog) => (
        <div
          className="blog-recommend"
          key={blog.id}
          onClick={() => handleClick(blog.id)}
        >
          <div className="dash-blog-img">
            <img src={blog.imageUrl} alt="" />
          </div>

          <div className="dash-details-and-new">
            <div className="dash-blog-container">
              <div className="dash-blog-detail">
                <h2>{blog.title}</h2>
                <p style={{ textAlign: "start" }}>{blog.description}</p>
                <div className="dash-authot-details">
                  <div className="dash-author-img">
                    <img src="" alt="" />
                  </div>
                  <div className="name-and-date">
                    <h6>Kiran Kumar</h6>
                    <p style={{ textAlign: "left" }}>{blog.publishedDate}</p>
                  </div>
                </div>

                <div className="dash-blog-likes-and-comment">
                  <div className="likes">
                    <p>{blog.likes} likes</p>
                  </div>
                  <div className="comments">
                    <p>{blog.commentsCount} comments</p>
                  </div>
                </div>
              </div>
            </div>
            ;
          </div>

          <div className="dash-new-show">
            <h2>New</h2>
          </div>
        </div>
      ))}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div className="blog-cards-container">
          {blogs.map((blog) => (
            <BlogCard blog={blog} />
          ))}
        </div>
      </div>
    </div>
  );
}
