import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const CircularProgressBar = ({ progress }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const circumference = 100 * Math.PI;
    const progressOffset = ((100 - progress) / 100) * circumference;

    setOffset(progressOffset - 10);
  }, [progress]);

  return (
    <svg width="100" height="100">
      {/* Background Circle */}
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="transparent"
        stroke="#C8E6C9"
        strokeWidth="10"
      />
      {/* Progress Circle */}
      {progress > 0 && (
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="transparent"
          stroke="#3FA959"
          strokeWidth="10"
          strokeDasharray="283"
          strokeDashoffset={offset}
          strokeLinecap="round"
          // transform="rotate(-90 50 50)"
        />
      )}

      <text x="50%" y="50%" textAnchor="middle" dy="0.3em" fontSize="16">
        {`${progress}/100`}
      </text>
    </svg>
  );
};

export default CircularProgressBar;
