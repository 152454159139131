import React, { useState } from "react";
import {
  Paper,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import "./CSS/FormFields.css";
import { TypeWriterComponent } from "../Atoms/TypeWriter";
import * as loginApi from "../service/LoginRegistrationApi";
import { useEffect } from "react";

let answers = [];

const handleMultiChoiceChange = (question, choice) => {
  console.log(choice);
};

const handleTextAnswerChange = (questionId, answer) => {
  console.log(answer);
};

const handleFileUpload = (event) => {
  // setFile(event.target.files[0]);
  console.log(event);
};

export function MultiChoice(props) {
  let question = props.question;
  return (
    <div className="multiChoice">
      <Typography variant="h6" gutterBottom>
        {question.question}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={answers[question.id] || "Java" || "Python"}
          onChange={(e) => handleMultiChoiceChange(question, e.target.value)}
        >
          {question.choices.map((choice, index) => (
            <FormControlLabel
              key={index}
              value={choice}
              control={<Radio />}
              label={choice}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <p>Answer: Java</p>
      <p>
        Explaination: Redux is not a core concept of React; it's a state
        management library that is often used with React but is not part of
        React itself.
      </p>
    </div>
  );
}

export function SingleChoice(props) {
  let question = props.question;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {question.question}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={answers[question.id] || ""}
          onChange={(e) => handleMultiChoiceChange(question, e.target.value)}
        >
          {question.choices.map((choice, index) => (
            <FormControlLabel
              key={index}
              value={choice}
              control={<Radio />}
              label={choice}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export function TextQuestion(props) {
  let question = props.question;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {question.question}
      </Typography>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        rows={4}
        value={answers[question.id] || ""}
        onChange={(e) => handleTextAnswerChange(question, e.target.value)}
      />
    </div>
  );
}

export function PracticeChallenge(props) {
  let question = props.question;

  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [code, setCode] = useState("");
  const [testCases, setTestCases] = useState("");
  const [output, setOutput] = useState("");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleTestCasesChange = (event) => {
    setTestCases(event.target.value);
  };
  const handleSubmitCode = () => {
    // In a real application, you would send the code to a server for storage and evaluation.
    // For simplicity, we'll just log it here.
    console.log("Code submitted:", code);
  };

  return (
    <Paper
      key={question.id}
      elevation={2}
      style={{ padding: "20px", marginBottom: "2px" }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <div style={{ width: "50%" }} variant="h6" gutterBottom>
          <p>{question.input}</p>
        </div>
        <div>
          <div className="language-select">
            <label>Select a language:</label>
            <select value={selectedLanguage} onChange={handleLanguageChange}>
              <option value="javascript">JavaScript</option>
              <option value="python">Python</option>
              <option value="java">Java</option>
              {/* Add more language options */}
            </select>
          </div>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            rows={4}
            value={answers[question.id] || ""}
            onChange={(e) => handleTextAnswerChange(question, e.target.value)}
          />
          <button onClick={handleSubmitCode}>Submit Code</button>
        </div>
      </div>
    </Paper>
  );
}

export function Upload(props) {
  let question = props.question;

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {question.question}
      </Typography>
      <input type="file" accept=".jpg,.png,.pdf" onChange={handleFileUpload} />
      {/* {file && <Typography>{file.name}</Typography>} */}
    </div>
  );
}

export function CodeQuestion(props) {
  let question = props.question;
  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [code, setCode] = useState("");
  const [testCases, setTestCases] = useState("");
  const [output, setOutput] = useState("");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleTestCasesChange = (event) => {
    setTestCases(event.target.value);
  };
  const handleSubmitCode = () => {
    // In a real application, you would send the code to a server for storage and evaluation.
    // For simplicity, we'll just log it here.
    console.log("Code submitted:", code);
  };

  return (
    <div>
      {question.question}
      <div>
        <div className="language-select">
          <label>Select a language:</label>
          <select value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="javascript">JavaScript</option>
            <option value="python">Python</option>
            <option value="java">Java</option>
            {/* Add more language options */}
          </select>
        </div>
      </div>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        rows={4}
        value={answers[question.id] || ""}
        onChange={(e) => handleTextAnswerChange(question, e.target.value)}
      />
      <button onClick={handleSubmitCode}>Submit Code</button>
    </div>
  );
}

export function ChatQuestion(props) {
  const [isBookMarked, setIsBookmarked] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  let question = props.question;

  useEffect(() => {
    loginApi.getUserInfo().then((data) => {
      console.log("data", data);
      return setUserInfo(data);
    });
  }, []);

  const handleBookmark = () => {
    setIsBookmarked(!isBookMarked);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <div className="form-user-inputs">
        <div className="input-box-color"></div>
        {userInfo && (
          <div className="user-chat-img" >

            <img src={userInfo.imageUrl} alt="" />
          </div>
        )}

        <p>{question.input}</p>
      </div>
      <div className="chat-response-box">
        <div className="chat-box-color"></div>
        <p style={{ width: "90%", textAlign: "start" }}>
          <TypeWriterComponent content={question.response} />
        </p>

        <div className="chat-response-actions">
          <i class="fa-regular fa-clipboard"></i>
          <i
            class={
              isBookMarked ? "fa-solid fa-bookmark" : "fa-regular fa-bookmark "
            }
            onClick={handleBookmark}
          ></i>
        </div>
      </div>
    </div>
  );
}
