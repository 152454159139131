import React, { useEffect } from "react";
import "./CSS/BlogCard.css";
import { Link, useNavigate } from "react-router-dom";

export function BlogCard(props) {
  const selectedPost = props.selectedPost;

  // Use the effect to scroll to the top when the component opens
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedPost]);
  let blog = props.blog;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blogs/${blog.id}`);
  };
  return (
    <div key={blog.id} className="blog-cards" onClick={handleClick}>
      {/* <Link to={`blogs/${blog.id}`}  className="blog-links"> */}

      <div className="blog-square-img">
        <img src="/images/temp/common.png" alt="/images/temp/default.png" />
      </div>

      <div className="blog-data">
        <div className="blog-title">
          <p>{blog.title.substring(0, 20)}...</p>
        </div>

        <div className="blog-data-desc">
          <p>{blog.description}</p>
        </div>

        <div className="views-comments">
          <div>
            <p>{blog.views} views</p>
          </div>
          <div>
            <p>{blog.commentsCount} comments</p>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
}
