import React from "react";
import { Link, useNavigate } from "react-router-dom";

const MentorCard = () => {

  const navigate = useNavigate();

  return (
    <div className="mentor-details">
      <p className="mentor-heading">Mentor</p>

      <div className="mentor-profile-pic">
        <div className="online-mark"></div>
        <img src="/images/mentor.svg" alt="" />
      </div>

      <div className="mentor-complete-detail">
        <div className="mentor-name-and-profession">
          <h5>Hari Srivathshav</h5>
          <p>Data Scientist</p>
        </div>

        <hr
          style={{
            width: "50%",
            backgroundColor: "#CD2626",
            height: ".01rem",
            outline: "none",
            border: "none",
          }}
        />

        <p className="mentor-intro">
          Hari is not just a data scientist; he's a data wizard! With a passion
          for crunching numbers and unlocking insights, he's been on the
          forefront of groundbreaking data projects.{" "}
        </p>

        <Link
          style={{
            color: "green",
            fontSize: ".8rem",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          Read more
        </Link>

        <div className="mentor-social-link">
          <i class="fa-brands fa-linkedin-in"></i>
          <i class="fa-brands fa-github"></i>
        </div>
      </div>

      <div className="contact-and-book">
        <div className="contact-me">
          <img src="/images/chat.svg" alt="" />
          <p>Contact Me</p>
        </div>
        <div className="book-session" onClick={() => navigate('/undermaintainance')}>
          <img src="/images/two-guys.svg" alt="" />
          <p>Book a session</p>
        </div>
      </div>
    </div>
  );
};

export default MentorCard;
