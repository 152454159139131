// ConfirmPopup.js
import React from "react";
import Modal from "react-modal";

const ConfirmPopup = ({ isOpen, message, onConfirm, onCancel }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Confirmation Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          width: "50%",
          height: "30%",
          margin: "auto",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        },
      }}
    >
      <h2>Confirm Action</h2>
      <p>{message}</p>
      <div style={{ display: "flex", gap: "2rem" }}>
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            outline: "none",
            border: "none",
            padding: ".5rem 1rem",
            borderRadius: "10px",
            width: "5rem",
            cursor: "pointer",
          }}
          onClick={onConfirm}
        >
          Yes
        </button>
        <button
          style={{
            backgroundColor: "red",
            color: "white",
            outline: "none",
            border: "none",
            padding: ".5rem 1rem",
            borderRadius: "10px",
            width: "5rem",
            cursor: "pointer",
          }}
          onClick={onCancel}
        >
          No
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmPopup;
