import * as Congs from './Configurations'

const jobDetails = {
  "jobID": 1,
  "jobCompanyName": "Bytecoder India",
  "websiteUrl":"http://byetcoder.in",
  "jobLogo": "https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg",
  "description": "Crafting delightful digital experiences, one pixel at a time. Bringing your vision to life with intuitive and visually stunning UI/UX design.",
  "overview": "Amazon India, a subsidiary of the global e-commerce powerhouse, Amazon.com, has been transforming the way India shops since 2013. We offer an extensive range of  products and services, spanning electronics, fashion, books, groceries, and more, catering to the diverse needs of Indian consumers.",
  "jobDescription": "Crafting delightful digital experiences, one pixel at a time. Bringing your vision to life with intuitive and visually stunning UI/UX design.",
  "jobTitle": "UI/UX Developer",
  "jobTags": ["Full Time","2+ Years","UG"],
  "location":"Hyderabad, Telengana",
  "requiredQualifications":[
  "Bachelor's (UG) or Master's (PG) degree in a related field.", "Proven experience in UI/UX design with a portfolio showcasing your work.",  "Proficiency in design software and prototyping tools.",
  "Strong problem-solving skills and attention to detail.",

  "Excellent communication and collaboration abilities."],

  "roleResponsibilies" : ["Collaborate with cross-functional teams to understand user needs and business goals.",
  "Design user interfaces that are intuitive, visually appealing, and responsive.", "Create wireframes, prototypes, and design mock-ups to convey design ideas.", "Conduct user research and usability testing to gather feedback for continuous improvement.",    "Stay updated on industry trends and best practices in UI/UX design."]

}

export function getAllJobOpennings(status)  {
  console.log("fetching getAllJobOpennings")
  return  fetch(Congs.GET_ALL_JOBS_OPENING + "?status="+status)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};


export function saveOrApplyJob(jobDto)  {
  console.log("fetching getAllJobOpennings")
  return  fetch(Congs.GET_ALL_JOBS_OPENING ,{
    method: "POST",
    body: JSON.stringify(jobDto),
    headers: Congs.HEADERS,
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};





export function getAllFilters()  {
  console.log("fetching getAllFilters")
  return  fetch(Congs.GET_ALL_JOBS_FILTERS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};




export function getJobById(code){
  console.log("fetching job details " +code)
 return fetch(Congs.GET_ALL_JOBS_OPENING + code)
  .then((response) => response.json())
  .then((json) => {
    console.log(json)
    return json
  });
}



