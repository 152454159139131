import React from "react";
import "./Bookmark.css";
import { ChatBar } from "../../../../Atoms/Buttons";
import { Link } from "react-router-dom";
import SubjectChatTags from "../../../../Molecule/SubjectChatTags";

const Bookmark = () => {
  return (
    <div className="bookmark-classes-container">
      <div className="bookmark-classes-name">
        <p style={{ fontSize: "1.4rem" }}>
          Subjects &gt;{" "}
          <span style={{ fontWeight: "bold" }}>Python Programming</span>
        </p>
      </div>

      <div className="bookmark-classes-and-name">
        <div className="bookmark-heading">
          <i class="fa-regular fa-bookmark"></i>
          <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>Bookmarks</p>
        </div>

        <div className="bookmarked-classes  ">
          <div className="bookmarked-class-name">
            <div className="hashtags-and-class">
              <div className="class-hastags">
                <p>#</p>
              </div>
              <div className="bookmark-class">
                <p>Operator in Java</p>
              </div>
            </div>
            <div className="down-arrow">
              <i class="fa-solid fa-angle-down"></i>
            </div>
          </div>
          {/* <div className="bookmarked-class-name">
            <div className="hashtags-and-class">
              <div className="class-hastags">
                <p>#</p>
              </div>
              <div className="bookmark-class">
                <p>Operator in Java</p>
              </div>
            </div>
            <div className="down-arrow">
              <i class="fa-solid fa-angle-down"></i>
            </div>
          </div>
          <div className="bookmarked-class-name">
            <div className="hashtags-and-class">
              <div className="class-hastags">
                <p>#</p>
              </div>
              <div className="bookmark-class">
                <p>Operator in Java</p>
              </div>
            </div>
            <div className="down-arrow">
              <i class="fa-solid fa-angle-down"></i>
            </div>
          </div>
          <div className="bookmarked-class-name">
            <div className="hashtags-and-class">
              <div className="class-hastags">
                <p>#</p>
              </div>
              <div className="bookmark-class">
                <p>Operator in Java</p>
              </div>
            </div>
            <div className="down-arrow">
              <i class="fa-solid fa-angle-down"></i>
            </div>
          </div> */}
        </div>
      </div>

      {/* Subject tags chat */}
      {/* <SubjectChatTags  /> */}
    </div>
  );
};

export default Bookmark;
