import React from "react";
import "./Assignment.css";
import { useNavigate, useParams } from "react-router-dom";
import { ASSIGNMENTS_CHAPTERS } from "../../../../service/Assignments";
import { useEffect } from "react";
import * as chapteApi from "../../../../service/Assignments";
import { useState } from "react";
import Loader from "../../../../Atoms/Loader";
import { ChaptersBar } from "../../../../Atoms/Buttons";

const Assignments = () => {
  const [selectedChapter, setSelectedChapter] = useState(1);
  const [courseChapters, setCourseChapters] = useState({chapters:[{assignments:[]}]});
  const params = useParams();

  const navigate = useNavigate();

  const takeAssesment = (id) => {
    navigate(
      "/programs/" + params.courseCode + "/learn/assignments/" + id
    );
  };

  useEffect(() => {
    chapteApi.getChaptersAssignment(params.courseCode).then((data) => {
      console.log(data);
      setCourseChapters(data);
    });
  }, []);

  return (
    <div className="heading-assignments-container">

      <div className="heading">
        <h2>Assignments</h2>
      
      <ChaptersBar
            chapters={courseChapters.chapters}
            setSelectedChapter={setSelectedChapter}
            selectedChapter={selectedChapter}
          />
      </div>
      
        <div className="assignments-container">
          {courseChapters.chapters[selectedChapter-1].assignments.map((test) => {
                      return (
                        <div key={test.id} className="assignment-card">
                          <div className="line-lighter"></div>
                          <p className="assignment-name">{test.title}</p>
                          <div
                            className="take-assigment"
                            onClick={()=>takeAssesment(test.id)}
                          >
                            <p>Take Assignment</p>
                          </div>
                        </div>
                      );
                    })}
        </div>
     
    </div>
  );
};

export default Assignments;
