import React, { useEffect, useState } from "react";
import "./JobsPage.css";
import JobCard from "../../Molecule/JobCard";
import * as jobsApiService from "../../service/JobsApiService";
import { useNavigate } from "react-router-dom";


export default function Jobs()  {

  const [jobs, setJobs] = useState([]);
  const [jobFilters, setJobFilters] = useState([{values:[]}]);
  const [searchJob, setSearchJob] = useState("");
  const [status, setStatus ] = useState("")

  const [jobeType, setJobeType ] = useState([])
  const [experienceLevel, setExperienceLevel ] = useState([])

  const handleSaveJobs = () => {
    setStatus(status ==="" ? "Saved" : "")
   
  };

  const setSearchJobHandler = (e) => {
    setSearchJob(e.target.value);
    console.log(searchJob)
  };

  const applyFilter =(event)=>{
    console.log(event.target.value)
  }

  useEffect(() => {
    jobsApiService.getAllJobOpennings(status).then((jobss) =>{
      setJobs(jobss)
      console.log(jobss)
      console.log(jobs)
    } );
    jobsApiService.getAllFilters().then((filters) => setJobFilters(filters));
  
  }, [status]);
 
 
  
  return (
    <div className="jobs-container">
      
      {/* <JobHeader getSearchJob={setSearchJob} /> */}

      <div className="job-search-and-save">
        <div className="job-search">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            value={searchJob}
            onChange={setSearchJobHandler}
            placeholder="search jobs"
          />
        </div>

        <div className="saved-jobs" style={{color: status== "Saved" ?"green" : "black"}} onClick={(handleSaveJobs)}>
          <i className="fa-regular fa-bookmark"></i> saved jobs
        </div>
      </div>

      <div className="jobs-and-filter">

        <div className="jobs-filter">
          <h4>Filters</h4>

          <div className="jobs-filter-options">
            {jobFilters.map(filter=>
              <div className="filter">
              <p>{filter.filterType}</p>
              {filter.values.map(value=>
                 <div className="checkboxes">
                 <input  type="checkbox" name={filter.filterType} value={value} id="" onChange={(event)=> applyFilter(event) } />
                 <label htmlFor="">{value}</label>
               </div>
                )}
            </div>
              )}

            </div>
        </div>

        <div className="all-jobs">
          {jobs.map((job) =>  < JobCard job={job} />)}
        </div>

      </div>
    </div>
  );
};

