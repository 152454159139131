import React, { useState, useEffect } from "react";
import "./Practice.css";
import { PRACTICE_CHAPTER } from "../../../../service/PracticeChapters";
import { useLocation, useNavigate, useParams } from "react-router";
import * as enrolledApi from "../../../../service/EnrolledApi";
import { ChaptersBar } from "../../../../Atoms/Buttons";

const Practice = () => {
  const [selectedChapter, setSelectedChapter] = useState(1);
  const params = useParams()
  const [coursePractices, setCoursePractices] = useState({ chapters: [{practices:[]}] });

  useEffect(() => {
    enrolledApi.getCoursePractices(params.courseCode).then((content) => {
      console.log(content)
      setCoursePractices(content)
    });
    
  }, []);

  const displayQuestions = (chapterID) => {
    setSelectedChapter(chapterID);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const goToProblems = (code) => {
    navigate(location.pathname + "/" + code + "1001");
  };

  return (
    <div className="practice-container">
      <div className="practice-name">
        <h2>Code Challenges</h2>
      </div>
      
      <div className="practice-chapters-and-questions">
      
          <ChaptersBar
            chapters={coursePractices.chapters}
            setSelectedChapter={setSelectedChapter}
            selectedChapter={selectedChapter}
          />
        <div className="practice-questions">
          {selectedChapter !== null && (
            <>
              {coursePractices.chapters[selectedChapter - 1].practices.map(
                (question) => (
                  <div
                    key={question.questionID}
                    className="practice-questions-card"
                  >
                    <div className="code-svg">
                      <i className="fa-solid fa-code"></i>
                    </div>

                    <div className="practice-question-details">
                      <h2 className="question-name">{question.questionTitle}</h2>
                      <p className="question-description">
                        {question.questionDescription}
                      </p>
                    </div>

                    <div className="solve-challenge-btn" onClick={goToProblems}>
                      <p>Solve Challenge</p>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>

      <div className="cloud-girl">
        <img src="/images/cloud-girl.svg" alt="" />
      </div>
    </div>
  );
};

export default Practice;
