import React, { useEffect, useState } from "react";
import "./App.css";
import Dashboard from "./Dashboard/Dashboard";

function App() {

  const [loggedin, setLoggedin]= useState(false)

  // const Loggedin = () => {
  //   localStorage.setItem("loggedin", "true");
  //   localStorage.setItem("token", "c1");
  //   console.log(localStorage.getItem("loggedin"));
  //   console.log(JSON.parse(localStorage.getItem("loggedin")));
  // };

  useEffect(()=>{
    console.log(localStorage.getItem("loggedin"))
    setLoggedin(localStorage.getItem("loggedin")=== "true" ? true : false)
  },[])

  return < Dashboard />;


}

export default App;
