import Chip from "@mui/material/Chip";
import React, { useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { TextField, Button, Paper } from "@mui/material";
import "./CSS/Button.css";
import { Link, useNavigate } from "react-router-dom";
import { EnrolledCourses } from "../service/EnrolledApi";
import { useEffect } from "react";
import ConfirmPopup from "./ConfirmPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function SelectButton(props) {
  let link = props.link;
  const isSelected = props.link === props.isSelected;
  const backgroundColor = isSelected ? "#4CAF50" : "inherit";
  return (
    <div>
      <Chip
        style={{ backgroundColor }}
        color={props.color}
        value={link}
        label={link}
        variant="outlined"
        onClick={() => props.handleClick(link)}
      />
    </div>
  );
}

export function SearchButton(props) {
  const navigate = useNavigate();

  const searchSubmit = (e) => {
    e.preventDefault();
  };

  const handleRegister = () => {
    if (props.searchText == "") return;
    navigate("/register");
  };

  return (
    <form className="search-btn" onSubmit={(event) => searchSubmit(event)}>
      <input
        style={{ fontSize: "20px", border: "none" }}
        type="search"
        placeholder="Enter your email"
        onChange={(event) => props.setSearchText(event.target.value)}
        value={props.searchText}
      />
      <button type="submit" onClick={handleRegister}>
        Register
      </button>
    </form>
  );
}

export function ChatBar(props) {
  const handleKeyPress = (e) => {
    if (props.inputText === "") return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent Enter key from adding a new line
      props.handleSendMessage();
    }
  };
  return (
    <div style={{ display: "flex" }} className="input-and-send">
      <div className="searching">
        <input
          label="Message"
          value={props.inputText}
          onChange={(e) => props.setinputText(e.target.value)}
          variant="outlined"
          margin="normal"
          onKeyDown={handleKeyPress}
          style={{ paddingLeft: "1rem", fontSize: ".9rem" }}
          placeholder="let's chat"
        />
        <img src="/images/mic.svg" alt="" style={{ cursor: "pointer" }} />
      </div>

      <div
        sx={{ margin: ".7em" }}
        variant="contained"
        color="primary"
        onClick={() => props.handleSendMessage(10)}
        className="send"
      >
        <img src="/images/white-send.svg" alt="" />
      </div>
    </div>
  );
}

export function ChaptersBar(props) {

  return (
    <div>
        <div className="chapters">
          <div className="chapter-heading">
            <h2> All Chapters</h2>
          </div>

          <hr style={{width:"100%", marginLeft:"1rem"}} />

          <div className="chapter-name">

            {props.chapters.map((practice) => (
              <div
                key={practice.practiceID}
                className={`chapter-number ${
                  props.selectedChapter === practice.chapterId ? "selected" : ""
                }`}
                onClick={() => props.setSelectedChapter(practice.chapterId)}
              >
                <p>{practice.chapterTitle}</p>
              </div>
            ))}
          </div>
        </div>

    </div>
  );
}

export function ClickableButton(props) {
  return (
    <div>
      <button
        className="clickable-btn"
        onClick={() => console.log("clicked ")}
        style={{ backgroundColor: props.color }}
      >
        {" "}
        {props.name}
      </button>
    </div>
  );
}

export function ViewAllCourses() {
  return (
    <div className="all-courses-btn">
      <Link to={"/programs"}>
        <button>View All Courses</button>
      </Link>
    </div>
  );
}

export function ViewAllBlogs() {
  return (
    <div className="all-blogs-btn">
      <Link style={{ color: "white" }} to="/blogs">
        More Blogs
      </Link>
    </div>
  );
}

export function JoinButton(props) {
  const { courseCode } = props;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);

  const handleEnrolledClick = () => {
    // Open the confirmation popup
    setIsConfirmationOpen(true);
  };

  console.log(courseCode);

  const handleJoinCancel = () => {
    // Close the confirmation popup without taking any action
    setIsConfirmationOpen(false);
  };

  const handleJoinConfirm = async () => {
    try {
      const codeData = {
        code: courseCode,
      };

      const response = await EnrolledCourses(codeData);
      if (response.status == 200) {
        // toast.success("successfully enrolled!");
        const result = await response.json();
      }
    } catch (error) {
    } finally {
      // Close the confirmation popup
      setIsConfirmationOpen(false);
    }

    // try {
    //   const codeData = {
    //     code: courseCode,
    //   };

    //   const response = await fetch(
    //     `http://bytecoder.in/back-end/api/v1/enrolled-courses/`,
    //     {
    //       method: "POST",
    //       body: JSON.stringify(codeData),
    //       headers: {
    //         "Content-Type": "application/json",
    //         token: "c1",
    //       },
    //     }
    //   ).catch((e) => console.log("Error " + e));

    //   const result = await response.json();
    //   console.log(result);
    // } catch (error) {
    //   console.log("you are facing the error --> ", error);
    // }
  };
  return (
    <div className="green">
      <Link style={{ cursor: "pointer" }} onClick={handleEnrolledClick}>
        <button>Join</button>
      </Link>

      {isConfirmationOpen && (
        <ConfirmPopup
          isOpen={isConfirmationOpen}
          message="Are you sure you want to join this course?"
          onConfirm={handleJoinConfirm}
          onCancel={handleJoinCancel}
        />
      )}
    </div>
  );
}
