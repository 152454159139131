import React from "react";
import QuestionerLeftLayer from "../../LoginPages/MaintainancePages/QuestionerLeftLayer";
import QuestionerRightLayer from "../../Molecule/QuestionerRightLayer";
import "./Questioner.css"

const Questioner = () => {
  return (
    <div className="questioner-container">
      <QuestionerLeftLayer />

      <QuestionerRightLayer />
    </div>
  );
};

export default Questioner;
