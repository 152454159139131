import React from "react";
import "./MyDashboard.css";
import "../Subjects/ProgramLearning/QuickNotes/QuickNotes.css";
import { useState } from "react";
import { useEffect } from "react";
import * as courseApi from "../../service/CoursesApi";
import * as JobsApi from "../../service/JobsApiService";
import NewEnrollDashboard from "./NewEnrollDashboard";
import { DASH_JOB_SHORTLIST_CARD } from "../../service/DashboardsProgressCard";
import { useNavigate } from "react-router";
import ProgressBar from "../../Atoms/ProgressBar";
import Loader from "../../Atoms/Loader";

const MyDashboard = () => {

  const [courses, setCourses] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    courseApi.getAllEnrolledCourses("", "", 4).then((result) => {
      console.log(result);
      return setCourses(result);
    });

    JobsApi.getAllJobOpennings("Saved").then(jobs=> {
      console.log( jobs)
      setAppliedJobs(jobs)
    })
  }, []);

  const navigate = useNavigate();

  const handleProgressCard = (code) => {
    
    navigate("/programs/"+code+"/learn/chapters");
  };

  const handleViewAllJobs = () => {
    navigate("/jobs");
  };

  const barFilledPercent = 20;

  if (courses.length == 0) {
    return <NewEnrollDashboard />;
    // return <Loader />
  } else {
    return (
      <div>
        <div className="enrolled-progress">
          <h3>Progress</h3>
          <div className="progress-card-container">
            {courses.map((course) => {
              return (
                <div
                  key={course.id}
                  className="progress-card"
                  onClick={()=> handleProgressCard(course.code)}
                >
                  <div className="progress-course-img" style={{height:'10em'}}>
                    <img src={course.imageUrl} alt="" />
                  </div>

                  <div className="progress-course-details">
                    <h4>{course.title.substring(0, 18)}...</h4>

                    <div className="prog-course-name-percent">
                      <div>
                        <p style={{ color: "lightgray" }}>Current Chapter</p>
                        <p>{course.currentChapterTitle}</p>
                      </div>

                      <div className="progress-peercent">
                        <p>{course.progress}%</p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "1rem",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                  >
                    <ProgressBar filled={course.progress} />
                  </div>

                  {/* <div className="course-progress-bar">
                    <div className="course-progress"></div>
                  </div> */}
                </div>
              );
            })}
          </div>
          <div className="Jobs">
          <h3>Job Portal</h3>          
          <div className="job-shortlisted">
            {/* <div className="applied-and-all">
              <p>Recently Applied</p>
              <p className="view-all-jobs" onClick={handleViewAllJobs}>
                view all
              </p>
            </div> */}

            <table className="job-shortlist-container">

              <tr className="job-shortlist-card-header">
                <th>Company</th>
                <th>Role</th>
                <th>Status</th>
              </tr>

              {appliedJobs.map((shortlist) => {
                return (
                  <tr key={shortlist.jobID} className="job-shortlist-card" onClick={handleViewAllJobs} >
 
                    <td className="job-log-and-name">
                      <div className="shortlist-logo">
                        <img src={shortlist.companyLogoUrl} alt="" />
                      </div>

                      <div className="company-name-and-time">
                        <p>{shortlist.companyName}</p>
                        <p>{shortlist.appliedTime}</p>
                      </div>
                    </td>

                    {/* <hr
                      style={{
                        width: "1px",
                        height: "2rem",
                        margin: 0,
                        backgroundColor: "grey",
                        border: "none",
                      }}
                    /> */}

                    <td className="shortlisted-role">
                      <p>{shortlist.title}</p>
                    </td>

                    {/* <hr
                      style={{
                        width: "1px",
                        height: "2rem",
                        margin: 0,
                        backgroundColor: "grey",
                        border: "none",
                      }}
                    /> */}

                    <td className="shortlist-status">
                      <p>
                        {" "}
                        <span style={{ color: "#0E882C" }}>
                          {shortlist.status}
                        </span>{" "}
                      </p>
                    </td>
                  </tr>
                );
              })}

        </table>
          </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MyDashboard;
