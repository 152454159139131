import React, { useEffect, useRef, useState } from "react";
import "./QuickNotes.css";
import * as CoursesApi from "../../../../service/CoursesApi";
import { Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SelectButton, ChatBar, ChaptersBar } from "../../../../Atoms/Buttons";
import { QUICK_LINKS } from "../../../../Constants/Constants";
import CustomFormField from "../../../../Molecule/CustomFormField";
import Bookmark from "../Bookmarks/Bookmark";
import * as enrolledApi from "../../../../service/EnrolledApi";
import { useLocation, useNavigate, useParams } from "react-router";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function SuggestedVideos(props) {
  let examples = CoursesApi.getSuggestedVideos(props.subject, props.topic);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {examples.map((example, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item>
                <p>{example.input}</p>
                <video src={example.response} type="video/mp4" controls>
                  {" "}
                </video>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

function SuggestedExamples(props) {
  let suggestExamples = CoursesApi.getSuggestedExamples(
    props.subject,
    props.topic
  );
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {suggestExamples.map((example, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item onClick={() => props.handleClick(example)}>
                {example.input} The useEffect Hook allows you to perform side
                effects in your components.
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export const QuickOptions = (props) => {
  console.log(props);
  if (props.selectedQuick === "Examples") {
    return (
      <SuggestedExamples
        subject={props.subject}
        topic={props.selectedTopic}
        handleClick={props.handleClick}
      ></SuggestedExamples>
    );
  } else if (props.selectedQuick === "Videos") {
    return (
      <SuggestedVideos subject={props.subject} topic={props.selectedTopic} />
    );
  }
  return <div></div>;
};

function QuickNotes(props) {
  const [selectedQuick, setSelectedQuick] = useState("");
  const [inputText, setinputText] = useState("");
  const [isBookmarked, setIsBookmarked] = useState(false);

  const subjectNotesListRef = useRef(null);

  const handleClick = (message) => {
   
  };

 
  useEffect(() => {
    if (selectedQuick === "Bookmarks") {
      setIsBookmarked(!isBookmarked);
    } else {
      setIsBookmarked(false);
    }
  }, [selectedQuick]);
  useEffect(() => {
    // Scroll to the bottom when a new message is added
    if (subjectNotesListRef.current) {
      subjectNotesListRef.current.scrollTop =
        subjectNotesListRef.current.scrollHeight;
    }
  }, []);

  const [selectedChapter, setSelectedChapter] = useState(1);

  const params = useParams()
  const [courseNotes, setCourseNotes] = useState({ chapters: [{notes:[]}] });

  const handleSendMessage = (id) => {
    console.log(id);
    if (inputText.trim() !== "") {
      console.log(inputText);
     
    }
  };
  useEffect(() => {
    enrolledApi.getCourseNotes(params.courseCode).then((content) => {
      console.log("Notes")
      console.log(content)
      setCourseNotes(content)
    });
    
  }, []);

  return (
    <div className="quick-notes">
        <div style={{ marginTop: "2em"}}>
        <h2>Notes</h2>
          <ChaptersBar
            chapters={courseNotes.chapters}
            setSelectedChapter={setSelectedChapter}
            selectedChapter={selectedChapter}
          />
        </div>
        <div
          className="subject-notes"
          style={{
            width: "70vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "top",
          }}
        >
          <div
            style={{
              width: "70vw",
              height: "80%",
              marginTop: "",
              // padding: "2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {isBookmarked ? (
              <Bookmark />
            ) : (
              <div className="subject-notes-list" ref={subjectNotesListRef}>
                {courseNotes.chapters[selectedChapter-1].notes.map((message) => (
                  <CustomFormField key={message.id} question={message} />
                ))}
              </div>
            )}
          </div>
          <QuickOptions
            subject={props.subject}
            selectedQuick={selectedQuick}
            handleClick={handleClick}
          />

          <div className="quick-link">
            {QUICK_LINKS.map((link) => (
              <SelectButton
                link={link}
                handleClick={(link) => setSelectedQuick(link)}
              ></SelectButton>
            ))}
          </div>

          <ChatBar
            inputText={inputText}
            setinputText={setinputText}
            handleSendMessage={handleSendMessage}
          />
        </div>
    </div>
  );
}

export default QuickNotes;
