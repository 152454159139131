import React, { useEffect, useState } from "react";
import "./JobDetailsPage.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as jobsApiService from "../../service/JobsApiService";
import { useScroll } from "react-spring";
import ConfirmPopup from "../../Atoms/ConfirmPopup";
import ProgressBar from '../../Atoms/ProgressBar'
import * as JobApiService from '../../service/JobsApiService'

const JobDetails = () => {
  const navigate = useNavigate();
  const [jobDetails, setjobDetails] = useState({
    jobType: [],
    requirements: [],
    responsibilities: [],
    aboutCompany: [],
    experience: [],
    educationLevel: [],
    location: [],
  });
  const params = useParams();

  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("Are you sure you want to apply for this job?");

  const handleBack = () => {
    navigate(-1);
  };
  console.log(params.id);

  const handleUnlock = () => {
    setIsConfirmPopupOpen(true);
  };

  // Handle confirmation of job application
  const handleConfirmApply = () => {
    setIsConfirmPopupOpen(false);
    if(jobDetails.isLocked){
      navigate("/jobs/"+params.code+"/assesment");
    }else{
      setIsConfirmPopupOpen(true);
      setPopupMessage("Thank you, Successfully applied for the job")
      
      navigate("/jobs")
    }
  };

  // Handle cancellation of job application
  const handleCancelApply = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleSaveJob = (jobID) => {
    console.log("save the job " + jobID)
    setjobDetails({
      ...jobDetails, status : jobDetails.status ==="" ? "Saved": ""
    })

    let jobDto = {
    code: jobDetails.code,
    companyCode: jobDetails.companyCode,
    uniqueUserId: localStorage.getItem("uniqueUserId"),
    status: jobDetails.status === "Saved" ? "Unsave" : "Save"
    }
    console.log(jobDto.status)
    JobApiService.saveOrApplyJob(jobDto)
    
  };

  useEffect(() => {
    // Filter jobs and set them when the component mounts
    jobsApiService
      .getJobById(params.code)
      .then((jobDetail) => setjobDetails(jobDetail));
  }, [params.id]);

  return (
    <div className="job-details-container">
      <div className="go-back" onClick={handleBack}>
        <i class="fa-solid fa-angle-left"></i>
        <h4>Go Back</h4>
      </div>

      <div>
        <div className="job-details-and-descriptions">
          <div className="job-detail">
            <div className="job-img">
              <img src={jobDetails.companyLogoUrl} alt="" />
            </div>

            <div className="job-title-and-location">
              <h2 className="job-company-name ">{jobDetails.companyName}</h2>
              <div className="job-location">
                <i class="fa-solid fa-location-dot"></i>
                <ul>
                {jobDetails.location.map((tag) => (
                <li>{tag}</li>
              ))}
              </ul>
              </div>
            </div>

            <div className="job-description">
              {/* <p>{jobDetails.jobDescription}</p> */}
            </div>

            <div className="job-tags">
              {jobDetails.jobType.map((tag) => (
                <h3>{tag}</h3>
              ))}
            </div>

            <div className="visit-cmpny">
              <Link
               target="_blank" 
                style={{ textDecoration: "none", color: "green;" }}
                to={jobDetails.companyWebsite}
              >
                Visit Company Site
              </Link>
            </div>
            <div className="job-matching">
              <h4>job matching </h4>
              <ProgressBar filled={jobDetails.matchRate} />
            </div>
              
          </div>

          <div className="job-title-and-descripiton">
            
            <div className="job-title-and-btn">
              <div className="title">
                <h2>{jobDetails.title}</h2>
                <p>{jobDetails.companyName}</p>
              </div>

              <div className="job-action-btn">
                <div className="unlock" onClick={handleUnlock}>
                  {jobDetails.isLocked ? <i class="fa-solid fa-lock"></i> : <i></i>}
                  <h4>{jobDetails.isLocked ? "Unlock" : "Apply"}</h4>
                </div>

                <ConfirmPopup
                  isOpen={isConfirmPopupOpen}
                  message={popupMessage}
                  onConfirm={handleConfirmApply}
                  onCancel={handleCancelApply}
                />

                <div className="save-job" onClick={() => handleSaveJob(jobDetails.jobID)}>
                  <h4>{jobDetails.status === "Saved" ? "Saved Job" : "Save Job"}</h4>
                </div>
              </div>
            </div>

            <hr />

            {/* Here our Mark down comes */}

            <div className="jobs-complete-details">
              <div className="jobs-descript">
                <h2>Job Description</h2>
                <p>{jobDetails.jobDescription}</p>
              </div>

              <div className="qualifications">
                <h2>Preffered Qualifications</h2>
                <ul>
                  {jobDetails.requirements.map((qualification) => (
                    <li> {qualification} </li>
                  ))}
                </ul>
              </div>

              <div className="responsibilities">
                <h2>Key Responsibilities</h2>
                <ul>
                  {jobDetails.responsibilities.map((responsibility) => (
                    <li>{responsibility}</li>
                  ))}
                </ul>
              </div>

              <div className="company-overview">
                <h2>Company Overview</h2>
                {jobDetails.aboutCompany.map(text=> <div><p>{text}</p></div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
