export const DASH_PROGRESS_CARD = [
  {
    courseID: 1,
    courseName: "Java Programming",
    courseChapterName: "Variables",
    courseProgress: 40,
  },
  {
    courseID: 2,
    courseName: "Python Programming",
    courseChapterName: "List",
    courseProgress: 60,
  },
  {
    courseID: 3,
    courseName: "Web Development",
    courseChapterName: "CSS",
    courseProgress: 20,
  },
  {
    courseID: 4,
    courseName: "Soft Skills",
    courseChapterName: "Communications",
    courseProgress: 50,
  },
];

export const DASH_JOB_SHORTLIST_CARD = [
  {
    jobID: 1,
    jobLogo:
      "https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg",
    JobCompaanyName: "Amazon",
    jobRole: "SDE1",
    jobStatus: "shortlisted",
    jobApplyDuration: "2 weeks Ago",
  },
  {
    jobID: 2,
    jobLogo: "https://blog.hubspot.com/hubfs/image8-2.jpg",
    JobCompaanyName: "Google",
    jobRole: "SDE1",
    jobStatus: "pending",
    jobApplyDuration: "1 weeks Ago",
  },
  {
    jobID: 3,
    jobLogo:
      "https://www.siegelgale.com/app/uploads/2021/10/SGCOM_Blog_211018.png",
    JobCompaanyName: "TCS",
    jobRole: "SDE1",
    jobStatus: "interviewing",
    jobApplyDuration: "2 weeks Ago",
  },
  {
    jobID: 4,
    jobLogo:
      "https://www.wipro.com/content/dam/nexus/en/brand/images/secondary-logo-400x276.png",
    JobCompaanyName: "Wipro",
    jobRole: "SDE2",
    jobStatus: "shortlisted",
    jobApplyDuration: "2 days Ago",
  },
];
