export const questionQuiz = {
  totalQuestions: 4,
  perQuestionScore: 5,
  questions: [
    {
      question: "What programming language do you prefer?",
      choices: [
        {
          imageUrl:
            "https://w1.pngwing.com/pngs/425/99/png-transparent-java-logo-programming-language-selenium-computer-software-java-class-file-computer-programming-software-developer-software-framework.png",
          questionName: "Java programming",
          questionDesc: "",
        },
        {
          imageUrl:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png",
          questionName: "Python programming",
          questionDesc: "",
        },
        {
          imageUrl:
            "https://w7.pngwing.com/pngs/520/669/png-transparent-c-logo-c-programming-language-computer-icons-computer-programming-programming-miscellaneous-blue-trademark.png",
          questionName: "C++ programming",
          questionDesc: "",
        },
        {
          imageUrl:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
          questionName: "Javascript programming",
          questionDesc: "",
        },
        {
          imageUrl:
            "https://w7.pngwing.com/pngs/980/847/png-transparent-ruby-on-rails-logo-programming-language-rubygems-ruby-angle-design-ruby.png",
          questionName: "Ruby programming",
          questionDesc: "",
        },
        {
          imageUrl:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png",
          questionName: "C# programming",
          questionDesc: "",
        },
      ],
      type: "MCQs",
      correctAnswer: "",
    },
    {
      question: "Please choose the learning mode to continue?",
      choices: [
        {
          questionName: "AI Based personalized learning",
          imageUrl: "/images/ai.svg",
          questionDesc:
            "Embrace a learning experience driven entirely by AI, where every aspect is personalized to your needs and guided by artificial intelligence.",
        },
        {
          questionName: "One to One Mentorshop Learning",
          imageUrl: "/images/hack.svg",
          questionDesc:
            "This one-on-one mentorship, combining AI and human touch, offers tailored learning experiences tailored to individual needs, expertly guided by AI and professionals.",
        },
      ],
      type: "MCQs",
      correctAnswer: "",
    },
    {
      question: "What Backend Tech do you prefer?",
      choices: [
        {
          questionName: "Java Programming",
          imageUrl:
            "https://w1.pngwing.com/pngs/425/99/png-transparent-java-logo-programming-language-selenium-computer-software-java-class-file-computer-programming-software-developer-software-framework.png",
          questionDesc: "",
        },
        {
          questionName: "Python Programming",
          imageUrl:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png",
          learningDesc: "",
        },
      ],
      type: "MCQs",
      correctAnswer: "",
    },
    {
      question: "How much time do you have for interview?",
      choices: [
        {
          questionName: "2 weeks",
          imageUrl: "/images/clock.svg",
          questionDesc:
            "",
        },
        {
          questionName: "4 weeks",
          imageUrl: "/images/clock.svg",
          learningDesc:
            "",
        },
        {
          questionName: "8 weeks",
          imageUrl: "/images/clock.svg",
          learningDesc:
            "",
        },
      ],
      type: "MCQs",
      correctAnswer: "",
    },
    // {
    //   question: "Tell us about the projects you’ve done?",
    //   choices: [
    //     {
    //       questionName: "2 weeks",
    //       imageUrl: "",
    //       questionDesc:
    //         "",
    //     },
    //   ],
    //   type: "textarea",
    //   correctAnswer: "",
    // },
  ],
};
