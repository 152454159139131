import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from '../Atoms/ProgressBar'
import * as JobApiService from '../service/JobsApiService'


const JobCard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [savedJobs, setSavedJobs] = useState([]);
  const [job, setJob] = useState(props.job);

 
  const handleApply = (code) => {
    navigate(`/job-details/${code}`);
  };

  const handleSaveJob = (jobID) => {
    console.log("save the job " + jobID)
    setJob({
      ...job, status : job.status ==="" ? "Saved": ""
    })

    let jobDto = {
    code: job.code,
    companyCode: job.companyCode,
    uniqueUserId: localStorage.getItem("uniqueUserId"),
    status: job.status === "Saved" ? "Unsave" : "Save"
    }
    console.log(jobDto.status)
    JobApiService.saveOrApplyJob(jobDto)
    
  };

 
  return (
    
          <div className="single-job-container">
            
            <div className="job-logo-and-company" style={{ display: "flex" }}>
              <div className="job-logo">
                <img src={job.companyLogoUrl} alt="" />
              </div>
              <h5 className="jobCmpnyName" style={{ margin: 0 }}>
                {job.companyName}
              </h5>
            </div>
            <div>
              <h5 className="jobTitle" style={{ margin: 0 }}>
                {job.title}
              </h5>
              <p className="jobs-description"> {job.jobDescription.substring(0, 200)} ...</p>
            </div>
            <div className="job-tags" style={{ display: "flex", gap: "1rem", justifyContent:'start' }}>
              {job.jobType.map((tag) => {
                return <h4>{tag}</h4>;
              })}
            </div>
           

            <div className="job-card-btn ">
              <div
                className="apply-job-btn"
                onClick={() => handleApply(job.code)}
              >
                View Details
              </div>
              <div
                  className="save-job-btn" 
                  onClick={() => handleSaveJob(job.jobID)}
                >
                  {job.status === "Saved" ? "Saved Job" : "Save Job"}
              </div>
            </div>
            <div style={{display:"flex"}}>
            {/* <p>job matching </p> */}
            <ProgressBar filled={job.matchRate} />
            </div>
          </div>
     
  );
};

export default JobCard;
