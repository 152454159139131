import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./UserProfile.css";
import * as loginApi from "../service/LoginRegistrationApi";
import Loader from "../Atoms/Loader";
import * as saveUser from "../service/LoginRegistrationApi";

function UserProfile() {
  const [userInfo, setUserInfo] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  useEffect(() => {
    loginApi.getUserInfo()
    .then((response) => {
      console.log(response)
      if(response.status == 200){
          return  response.json()
      }else{
        window.open("https://bytecoder.in/login", '_self')
        return  response.json()
      }
    })
    .then((json) => json)    
    .then((data) => {
      console.log("data", data);
      return setUserInfo(data);
    }) .catch((e) => 
    {
      console.log("Error " + e)
      window.open("https://bytecoder.in/login", '_self')

    });
    
   
  }, []);

  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const phoneInputRef = useRef(null);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleEditClick = (inputRef) => {
    setEditMode(true);
    setSelectedInput(inputRef);
    inputRef.current && inputRef.current.focus();
  };

  const handleSaveChanges = () => {
    setEditMode(false);
    setSelectedInput(null);

    saveUser.updateUserInfo(userInfo).then((res) => {
      console.log(res);
    });

    console.log("Saved user information:", userInfo);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div>
      {userInfo ? (
        <div className="user-profile-container">
          <div className="user-profile-detials-box">
            <p
              style={{
                alignSelf: "start",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              My Profile
            </p>

            <div className="user-dash-profile-img">
              <img src={userInfo.imageUrl== null ? "./images/profile_image.png" : userInfo.imageUrl} alt="" />
            </div>

            <div className="user-profile-data">
              <form className="profile-form" action="">
                <div className="user-name">
                  <label htmlFor="">Full Name</label>
                  <div
                    className={`input-div ${
                      selectedInput === nameInputRef ? "focused" : ""
                    }`}
                  >
                    <input
                      type="text"
                      ref={nameInputRef}
                      name="name"
                      value={userInfo.name}
                      onChange={handleInputChange}
                      readOnly={!editMode || selectedInput !== nameInputRef}
                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(nameInputRef)}
                    ></i>
                  </div>
                </div>

                <div className="user-email">
                  <label htmlFor="">Email</label>
                  <div
                    className={`input-div ${
                      selectedInput === emailInputRef ? "focused" : ""
                    }`}
                  >
                    <input
                      type="email"
                      ref={emailInputRef}
                      name="email"
                      value={userInfo.email}
                      onChange={handleInputChange}
                      readOnly={!editMode || selectedInput !== emailInputRef}
                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(emailInputRef)}
                    ></i>
                  </div>
                </div>
                <div className="user-password">
                  <label htmlFor="">Password</label>
                  <div
                    className={`input-div ${
                      selectedInput === passwordInputRef ? "focused" : ""
                    }`}
                  >
                    <input
                      type="password"
                      ref={passwordInputRef}
                      name="password"
                      value={userInfo.password}
                      onChange={handleInputChange}
                      readOnly={!editMode || selectedInput !== passwordInputRef}
                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(passwordInputRef)}
                    ></i>
                  </div>
                </div>
                <div className="user-number">
                  <label htmlFor="">Mobile number</label>
                  <div
                    className={`input-div ${
                      selectedInput === phoneInputRef ? "focused" : ""
                    }`}
                  >
                    <input
                      type="number"
                      ref={phoneInputRef}
                      name="phone"
                      value={userInfo.phone}
                      onChange={handleInputChange}
                      readOnly={!editMode || selectedInput !== phoneInputRef}
                    />

                    <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() => handleEditClick(phoneInputRef)}
                    ></i>
                  </div>
                </div>
              </form>
              {editMode && (
                <div className="save-profile" onClick={handleSaveChanges}>
                  <p>save changes</p>
                </div>
              )}
              <div
                className="customize-profile"
                onClick={() => navigate("/questioner")}
              >
                <p>customize learning</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

    </div>
  );
}

export default UserProfile;
